<template>
  <controlled-entry-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by type -->
      <div class="table-filter-row">
        <label class="table-filter-label">Filter by type</label>
        <vue-select :options="typeOptions" :reduce="({ value }) => value" placeholder="All types" v-model="type" />
      </div>

      <div class="spacer"></div>

      <!-- Filter by area -->
      <div class="table-filter-row">
        <label class="table-filter-label">Filter by zone</label>
        <phillip-select select="area" placeholder="All zones" v-model="area" />
      </div>
    </template>
  </controlled-entry-table-base>
</template>

<script>
import ControlledEntryTableBase from './ControlledEntryTableBase';
import VueSelect from 'vue-select';

export default {
  name: 'controlled-entry-table',
  components: {
    ControlledEntryTableBase,
    VueSelect,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    typeOptions() {
      return [
        { label: '2N', value: '2n' },
        { label: 'Home Assistant', value: 'ha' },
      ];
    },
    _filters() {
      const filters = {};

      if (this.area) {
        filters['areaId.eq'] = this.area;
      }

      if (this.type) {
        filters['type.eq'] = this.type;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  data() {
    return {
      area: null,
      type: null,
    };
  },
  methods: {
    clearFilters() {
      this.area = null;
      this.type = null;
    },
  },
};
</script>
