<template>
  <component :is="icon"></component>
</template>

<script>
import FeatherAlertCircle from './FeatherAlertCircle.vue';
import FeatherAlertOctagon from './FeatherAlertOctagon.vue';
import FeatherAlignJustify from './FeatherAlignJustify.vue';
import FeatherArrowRightCircle from './FeatherArrowRightCircle.vue';
import FeatherArrowRight from './FeatherArrowRight.vue';
import FeatherBatteryCharging from './FeatherBatteryCharging.vue';
import FeatherBookOpen from './FeatherBookOpen.vue';
import FeatherBookmark from './FeatherBookmark.vue';
import FeatherCamera from './FeatherCamera.vue';
import FeatherCompass from './FeatherCompass.vue';
import FeatherCreditCard from './FeatherCreditCard.vue';
import FeatherFastForward from './FeatherFastForward.vue';
import FeatherHelpCircle from './FeatherHelpCircle.vue';
import FeatherHome from './FeatherHome.vue';
import FeatherLogOut from './FeatherLogOut.vue';
import FeatherMail from './FeatherMail.vue';
import FeatherMapPin from './FeatherMapPin.vue';
import FeatherTool from './FeatherTool.vue';
import FeatherPhoneForwarded from './FeatherPhoneForwarded.vue';
import FeatherPhoneIncoming from './FeatherPhoneIncoming.vue';
import FeatherPrinter from './FeatherPrinter.vue';
import FeatherSearch from './FeatherSearch.vue';
import FeatherSend from './FeatherSend.vue';
import FeatherSun from './FeatherSun.vue';
import FeatherTruck from './FeatherTruck.vue';
import FeatherUmbrella from './FeatherUmbrella.vue';
import FeatherUnlock from './FeatherUnlock.vue';
import FeatherUser from './FeatherUser.vue';
import FeatherUsers from './FeatherUsers.vue';

export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  components: {
    FeatherAlertCircle,
    FeatherAlertOctagon,
    FeatherAlignJustify,
    FeatherArrowRightCircle,
    FeatherArrowRight,
    FeatherBatteryCharging,
    FeatherBookOpen,
    FeatherBookmark,
    FeatherCamera,
    FeatherCompass,
    FeatherCreditCard,
    FeatherFastForward,
    FeatherHelpCircle,
    FeatherHome,
    FeatherLogOut,
    FeatherMail,
    FeatherMapPin,
    FeatherTool,
    FeatherPhoneForwarded,
    FeatherPhoneIncoming,
    FeatherPrinter,
    FeatherSearch,
    FeatherSend,
    FeatherSun,
    FeatherTruck,
    FeatherUmbrella,
    FeatherUnlock,
    FeatherUser,
    FeatherUsers,
  },
};
</script>
