<template>
  <nav class="navbar">
    <a href="#" class="sidebar-toggler">
      <icon icon="feather-align-justify" />
    </a>
    <div class="navbar-content">
      <search />
      <ul class="navbar-nav">
        <li class="nav-item dropdown nav-profile">
          <a
            id="profileDropdownToggle"
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click="toggleProfile"
          >
            <img src="../../assets/avatar.png" alt="profile" />
          </a>
          <div :style="{ display: profileVisible ? 'block' : '' }" class="dropdown-menu" id="profileDropdown">
            <div class="dropdown-header d-flex flex-column align-items-center">
              <div class="figure mb-3">
                <img src="../../assets/avatar.png" alt="" />
              </div>
              <div class="info text-center">
                <p class="name font-weight-bold mb-0">{{ session.name }}</p>
                <p class="email text-muted mb-3">{{ session.email }}</p>
              </div>
            </div>
            <div class="dropdown-body">
              <ul class="profile-nav p-0 pt-3">
                <li class="nav-item">
                  <a
                    :href="`https://hagiborwa.phillip.city?jwt=${session.jwt}`"
                    rel="external"
                    target="_blank"
                    class="nav-link"
                  >
                    <icon icon="feather-arrow-right-circle" />
                    <span>Hagibor Web View</span>
                  </a>
                </li>
              </ul>
              <hr style="border-color: #f2f4f9; opacity: 1" />
              <ul class="profile-nav p-0">
                <li class="nav-item">
                  <router-link :to="`/user/detail/${session.id}`" class="nav-link">
                    <icon icon="feather-user" />
                    <span>User detail</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/change-password" class="nav-link">
                    <icon icon="feather-unlock" />
                    <span>Change password</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#logout" @click.prevent="logout">
                    <icon icon="feather-log-out" />
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import session from '../../api/session';
import Search from './search/Search';

export default {
  name: 'dashboard-layout-navbar',
  components: {
    Search,
  },
  data() {
    return {
      session,
      profileVisible: false,
      windowClickHandler: null,
    };
  },
  methods: {
    logout() {
      session.logout();
    },
    toggleProfile() {
      this.profileVisible = !this.profileVisible;
    },
  },
  created() {
    this.windowClickHandler = (e) => {
      if (!this.profileVisible) {
        return; // Nothing to do here
      }

      /** @type {HTMLElement} */
      let element = e.target;

      while (element != null) {
        if (element.id === 'profileDropdown' || element.id === 'profileDropdownToggle') {
          return; // Do not close the dropdown
        }

        element = element.parentElement;
      }

      this.profileVisible = false;
    };

    window.addEventListener('click', this.windowClickHandler);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickHandler);
  },
};
</script>

<style lang="scss">
#profileDropdown {
  top: 59px;
}
</style>
